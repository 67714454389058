import React from 'react';
import Header from '../Header/Header';
import useDeviceHeight from '../../customHooks/useDeviceHeight';
import { MasterLayoutProps } from './MasterLayout.model';

const MasterLayout: React.FC<MasterLayoutProps> = (props) => {
  const { children, languages = undefined, logoUrl = undefined, bgColor = '#ffffff' } = props;

  const deviceHeight = useDeviceHeight();

  return (
    <div
      style={{
        position: 'relative',
        minHeight: deviceHeight || '100vh',
        backgroundColor: bgColor,
        paddingBottom: 70,
      }}
    >
      {logoUrl && languages && <Header logoUrl={logoUrl} languages={languages} />}

      {children}
    </div>
  );
};

export default MasterLayout;
