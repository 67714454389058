/**
 * @function getCurrentLanguage - get current language saved or detect by browser language
 *
 * @returns {string} currentLanguage
 */
export const getCurrentLanguage = (): string => {
  //check if language is stored (after user manual change)
  const storageLanguage = sessionStorage.getItem('language');
  let currentLanguage: string;
  if (storageLanguage) {
    currentLanguage = storageLanguage;
  } else {
    //if not in storage take it from the navigator
    currentLanguage = navigator.language;
  }
  return currentLanguage;
};

/**
 * @method setCurrentLanguage - set new current language
 * @param {string} code - new language code
 */
export const setCurrentLanguage = (code: string) => {
  sessionStorage.setItem('language', code);
};
