import React from 'react';

import './CustomInput.sass';
import { CustomInputProps } from './CustomInput.model';

const CustomInput: React.FC<CustomInputProps> = ({ name, label, type, onChange, value }) => {
  let inputMarkup: JSX.Element;

  switch (type) {
    case 'textarea':
      inputMarkup = <textarea></textarea>;
      break;
    case 'checkbox':
      inputMarkup = (
        <div className="checkbox-wrapper">
          <span className="check"></span>
          <input defaultValue={value} onChange={(e) => onChange(name, e.target.value)} name={name} id={name} type={type} />
        </div>
      );
      break;
    default:
      inputMarkup = <input defaultValue={value} onChange={(e) => onChange(name, e.target.value)} name={name} id={name} type={type} />;
  }

  return (
    <div className={`input-container ${type === 'checkbox' ? 'checkbox-container' : ''}`}>
      <label htmlFor={name}>{label}</label>
      {inputMarkup}
    </div>
  );
};

export default CustomInput;
