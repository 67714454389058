import { instance } from '../App';

export type Allergen = {
  number: number;
  title: string;
  description: string;
};

export type AllergensInfo = {
  titleModal: string;
  law: string;
  description: string;
  label: string;
  allergens: Array<Allergen>;
};

/**
 * @function getAllergens - get allergens info (modal)
 *
 * @returns {Promise<AllergensInfo>} allergens list detail
 */
const getAllergens = async (): Promise<AllergensInfo> => {
  let response: Promise<any>;
  response = await instance.get('/get_allergens.php').then((resp) => resp.data);
  return response;
};

export default getAllergens;
