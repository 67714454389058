import React from 'react';
import { Link, useParams } from 'react-router-dom';

import './Bottombar.sass';
import { BottombarProps } from './Bottombar.model';

const Bottombar: React.FC<BottombarProps> = ({ phoneNumber, itemsInCart }) => {
  const { restaurantName } = useParams();

  return (
    <div className="bottombar">
      <Link to={`/${restaurantName}`}>
        <span className="icon-container">
          <span className="icon">
            <img src="/img/icons/home.svg" alt="" />
          </span>
        </span>
      </Link>
      <a href={`tel:${phoneNumber}`}>
        <span className="icon-container">
          <span className="icon">
            <img src="/img/icons/phone.svg" alt="" />
          </span>
        </span>
      </a>
      <Link to={`/${restaurantName}/carrello`}>
        <span className="icon-container">
          {itemsInCart && <span className="active"></span>}
          <span className="icon">
            <img src="/img/icons/cart.svg" alt="" />
          </span>
        </span>
      </Link>
    </div>
  );
};

export default Bottombar;
