import React, { useEffect } from 'react';

import './ThankyouPage.sass';
import { useLocation } from 'react-router-dom';

const ThankyouPage = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orderNumber = query.get('orderNumber');

  useEffect(() => {
    //chiamata per ricevere i dati di questo ordine
    console.log(orderNumber);
  }, [orderNumber]);

  return (
    <div className="shopping-bag">
      <div className="cart">
        <div className="check-mark">
          <img src="/img/icons/check.svg" alt="" />
          <div className="title">Grazie!</div>
          <div className="description">Il suo ordine è stato inoltrato con successo. Seguirà una comunicazione da parte del ristoratore per la conferma sulla consegna.</div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouPage;
