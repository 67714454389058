import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoute from './routes/mainRoute';
import axios from 'axios';
import { getCurrentLanguage } from './services/currentLanguageManager';

export const instance = axios.create({
  baseURL: 'https://takeaway.risto.menu/api',
  withCredentials: true,
});

// Add a request interceptor
instance.interceptors.request.use(
  //resolve
  (config) => {
    config.params = {
      ...config.params,
      language: getCurrentLanguage(),
    };
    return config;
  },
  //catch
  (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
  //resolve
  (response) => response,
  //catch
  (error) => {
    //redirect if server go 500
    window.location.href = '/';
    return Promise.reject(error);
  }
);

function App() {
  return (
    <Router>
      <MainRoute />
    </Router>
  );
}

export default App;
