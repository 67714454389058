import React, { SyntheticEvent } from 'react';

import { Link, useParams, useHistory } from 'react-router-dom';
import CustomInput from '../../../components/CustomInput/CustomInput';

const Checkout = () => {
  const { restaurantName } = useParams();
  const history = useHistory();

  const onChange = (key: string, value: string) => {
    console.log(key, value);
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    //Check all fields
    alert('submit form');
    history.push(`/${restaurantName}/conferma-ordine?orderNumber=${450}`);
  };

  return (
    <div className="shopping-bag">
      <div className="heading">
        <div className="title">I tuoi dati</div>
        <Link to={`/${restaurantName}/carrello`}>Torna indietro</Link>
      </div>
      <div className="cart">
        <form onSubmit={onSubmit} action="">
          <CustomInput value="" name="name" label="Nome" onChange={onChange} type="text" />
          <CustomInput value="" name="surname" label="Cognome" onChange={onChange} type="text" />
          <CustomInput value="" name="address" label="Indirizzo" onChange={onChange} type="text" />
          <CustomInput value="" name="cap" label="CAP" onChange={onChange} type="text" />
          <CustomInput value="" name="city" label="Città" onChange={onChange} type="text" />
          <CustomInput value="" name="phone" label="Telefono" onChange={onChange} type="tel" />
          <CustomInput value="" name="mail" label="E-Mail" onChange={onChange} type="mail" />
          <CustomInput value="" name="note" label="Note" onChange={onChange} type="textarea" />
          <CustomInput
            value=""
            name="privacy"
            label={
              <>
                Consento al trattamento dei miei dati personali ai sensi dell’art. 13 del Regolamento UE n. 2016/679 <br /> ( <a href="www.google.com">leggi policy</a> )
              </>
            }
            onChange={onChange}
            type="checkbox"
          />

          <CustomInput
            value=""
            name="newsletter"
            label={
              <>
                Autorizzo il trattamento dei dati personali per l'invio di materiale informativo e pubblicitario come indicato nell'Informativa <br /> ({' '}
                <a href="www.google.com">leggi policy</a> )
              </>
            }
            onChange={onChange}
            type="checkbox"
          />

          <button className="btn btn-confirm-order">Invia l'ordine</button>
        </form>
      </div>
    </div>
  );
};

export default Checkout;
