import { instance } from '../App';

export type Dish = {
  id: string;
  name: string;
  price: string;
  imgUrl: string;
  description: string;
  allergens: Array<number>;
};

export type DishesList = {
  name: string;
  id: string;
  dishes: Array<Dish>;
};

export type ProposalDetail = {
  dishesList: Array<DishesList>;
  otherInfo: string;
};

/**
 * @function getProposalDetail - get proposal detail of a restaurant by given name
 * @param {string} ristoName name of the restaurant
 * @param {string} proposal name of the proposal
 *
 * @returns {Promise<ProposalDetail>} proposal detail
 */
const getProposalDetail = async (ristoName: string, proposal: string): Promise<ProposalDetail> => {
  let response: Promise<ProposalDetail>;
  response = await instance
    .get('/get_proposal_detail.php', {
      params: {
        slug: ristoName,
        menu: proposal,
      },
    })
    .then((resp) => resp.data);
  return response;
};

export default getProposalDetail;
