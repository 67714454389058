import React, { useState } from 'react';
import { ProposalDetailProps } from './ProposalDetail.model';
import './ProposalDetail.sass';
import AllergensModal from '../AllergensModal/AllergensModal';
import QuantitySelector from '../QuantitySelector/QuantitySelector';
import { useRestaurantProviderContext } from '../../routes/Restaurant/Restaurant';

type FormData = {
  [id: string]: number;
};

const ProposalDetail: React.FC<ProposalDetailProps> = ({ proposalDetail }) => {
  const { dishesList, otherInfo } = proposalDetail;
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({});
  const { shoppingCart, updateShoppingCart } = useRestaurantProviderContext();

  /**
   * @function openGroup - open accordion and close others
   * @param {string} id - accordion id to open
   */
  const openGroup = (id: string) => {
    if (activeId === id) {
      setActiveId(undefined);
    } else {
      setActiveId(id);
    }

    const dishesWrapper = document.querySelector(`.group-${id} .dishes-wrapper`) as HTMLElement;
    if (dishesWrapper) {
      const scrollHeight = dishesWrapper.scrollHeight;
      dishesWrapper.style.maxHeight = `${scrollHeight}px`;
    }
  };

  const addToCart = (id: string) => {
    updateShoppingCart(id, formData[id]);
  };

  const updateValue = (id: string, value: number) => {
    setFormData({ ...formData, [id]: value });
  };

  return (
    <>
      <div className="proposal-detail">
        <div className="dishes-container">
          {dishesList.map(({ name, dishes, id }) => (
            <div key={id} className={`dishes-group group-${id} ${activeId === id ? 'active' : ''}`}>
              <div className="group-name" onClick={() => openGroup(id)}>
                <span dangerouslySetInnerHTML={{ __html: name }} />
                <div className="arrow-down" />
              </div>
              <div className="dishes-wrapper">
                {dishes.map(({ id, name, imgUrl, price, description, allergens }) => (
                  <div className="single-dish" key={id}>
                    <div
                      className={`dish-heading-row ${!imgUrl ? 'no-bg' : ''}`}
                      style={{
                        backgroundImage: imgUrl ? `url("${imgUrl}")` : undefined,
                      }}
                    >
                      <div className="text-holder">
                        <div className="name" dangerouslySetInnerHTML={{ __html: name }} />
                      </div>
                    </div>
                    <div className="dish-body">
                      <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                      <div onClick={() => setModalOpen(true)} className="allergens-wrapper">
                        <span>Allergeni: </span>
                        {allergens.map((allergen) => (
                          <img key={`${id}-${allergen}`} src={`/img/allergens/${allergen}.svg`} alt="" />
                        ))}
                      </div>
                      <div className="product-footer">
                        <div className="price">
                          <span className="dt">Price</span>
                          <span className="dd">{price}</span>
                        </div>
                        <div className="actions-wrapper">
                          <QuantitySelector onUpdate={(value) => updateValue(id, value)} value={formData[id] || 1} />
                          <button onClick={() => addToCart(id)} className="btn btn-add-to-cart">
                            <img src="/img/icons/addcart.svg" alt="" />({shoppingCart[id] ? (formData[id] === shoppingCart[id] ? 'Aggiunto' : 'Aggiorna') : 'Aggiungi'})
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {otherInfo && (
          <div className="other-info-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <g transform="translate(-32.255 -29.917)">
                <path d="M501.6,157.9c0-47.1-38.2-85.3-85.3-85.3c-71,0-185,0-256,0c-47.1,0-85.3,38.2-85.3,85.3v320   c0,8.6,5.2,16.4,13.2,19.7s17.2,1.5,23.3-4.6c0,0,41.5-41.5,66.6-66.6c8-8,18.9-12.5,30.2-12.5h208.2c47.1,0,85.3-38.2,85.3-85.3   V157.9z M458.9,157.9c0-23.6-19.1-42.7-42.7-42.7c-71,0-185,0-256,0c-23.6,0-42.7,19.1-42.7,42.7v268.5l30.2-30.2   c16-16,37.7-25,60.3-25h208.2c23.6,0,42.7-19.1,42.7-42.7V157.9z M309.6,307.3v-64c0-11.8-9.6-21.3-21.3-21.3   c-11.8,0-21.3,9.6-21.3,21.3v64c0,11.8,9.6,21.3,21.3,21.3C300,328.6,309.6,319,309.6,307.3z M288.3,157.9   c11.8,0,21.3,9.6,21.3,21.3c0,11.8-9.6,21.3-21.3,21.3c-11.8,0-21.3-9.6-21.3-21.3C266.9,167.5,276.5,157.9,288.3,157.9z" />
              </g>
            </svg>
            <div className="info-text" dangerouslySetInnerHTML={{ __html: otherInfo }} />
          </div>
        )}
      </div>
      <AllergensModal isOpen={modalOpen} closeCallback={() => setModalOpen(false)} />
    </>
  );
};

export default ProposalDetail;
