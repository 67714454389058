import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Home from './Home/Home';
import Restaurant from './Restaurant/Restaurant';

const MainRoute = () => {
  let location = useLocation();

  useEffect(() => {
    //scroll to top every route's change
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Switch>
      <Route path="/:restaurantName/:proposalName" component={Restaurant} />
      <Route path="/:restaurantName" component={Restaurant} />
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default MainRoute;
