import React, { useEffect, useState, useContext, createContext } from 'react';
import getGeneralInfo from '../../services/getGeneralInfo';
import MasterLayout from '../../components/MasterLayout/MasterLayout';
import { useParams, Switch, Route } from 'react-router-dom';
import './Restaurant.sass';
import { RestaurantProps } from './Restaurant.model';
import ProposalList from '../../components/ProposalList/ProposalList';
import getProposals, { ProposalListModel } from '../../services/getProposals';
import ProposalDetail from '../../components/ProposalDetail/ProposalDetail';
import getProposalDetail, { ProposalDetail as ProposalDetailModel } from '../../services/getProposalDetail';
import Bottombar from '../../components/Bottombar/Bottombar';
// import Footer from '../../components/Folder/Footer';
import Checkout from './Checkout/Checkout';
import ShoppingBag from './ShoppingBag/ShoppingBag';
import ThankyouPage from './ThankyouPage/ThankyouPage';

type RestaurantProviderContextProps = {
  shoppingCart: {
    [id: string]: number;
  };
  updateShoppingCart: (id: string, quantity: number) => void;
};

const RestaurantProviderContext = createContext<RestaurantProviderContextProps>({
  shoppingCart: {},
  updateShoppingCart: () => {},
});

export const useRestaurantProviderContext = (): RestaurantProviderContextProps => useContext(RestaurantProviderContext);

const Restaurant: React.FC<RestaurantProps> = (props: RestaurantProps) => {
  const [generalInfo, setGeneralInfo] = useState<RestaurantProps | undefined>(undefined);
  const [proposals, setProposals] = useState<ProposalListModel | undefined>(undefined);
  const [proposalDetail, setProposalDetail] = useState<ProposalDetailModel | undefined>(undefined);
  const { restaurantName, proposalName } = useParams();
  const [shoppingCart, setShoppingCart] = useState({});

  useEffect(() => {
    const restaurantNameToAsk = String(restaurantName);
    getGeneralInfo(restaurantNameToAsk).then((newGeneralInfo) => {
      setGeneralInfo(newGeneralInfo);
    });
    getProposals(restaurantNameToAsk).then((newProposals) => {
      setProposals(newProposals);
    });
  }, [restaurantName]);

  useEffect(() => {
    if (proposalName) {
      getProposalDetail(restaurantName, proposalName).then((newProposalDetail) => {
        setProposalDetail(newProposalDetail);
      });
    } else {
      setProposalDetail(undefined);
    }
  }, [restaurantName, proposalName]);

  const { name, logoUrl, description, headingRowImgUrl, languages, customColors } = generalInfo || {};

  const { bgColor = '#f05858' } = customColors || {};

  const updateShoppingCart = (id: string, quantity: number) => {
    // ajax call in order to update
    // al then aggiornare shoppingCart
    setShoppingCart({ ...shoppingCart, [id]: quantity });
  };

  const context = {
    shoppingCart,
    updateShoppingCart,
  };

  return (
    <RestaurantProviderContext.Provider value={context}>
      {generalInfo ? (
        <MasterLayout bgColor={bgColor} logoUrl={logoUrl} languages={languages}>
          <Switch>
            <Route path="/:restaurantName/conferma-ordine">
              <ThankyouPage />
            </Route>
            <Route path="/:restaurantName/carrello">
              <ShoppingBag />
            </Route>
            <Route path="/:restaurantName/checkout">
              <Checkout />
            </Route>
            <Route path="/:restaurantName/:proposalName">
              <ProposalList horizontalLayout customColors={customColors || undefined} proposals={proposals} />
              {proposalDetail && <ProposalDetail proposalDetail={proposalDetail} />}
            </Route>
            <Route path="/:restaurantName">
              <div className="heading-row" style={{ backgroundImage: `url("${headingRowImgUrl}")` }}>
                <div className="title-holder">
                  <span>Benvenuto da:</span>
                  <h1 dangerouslySetInnerHTML={{ __html: name || '' }} />
                </div>
              </div>
              <div className="body-holder">
                <div className="description" dangerouslySetInnerHTML={{ __html: description || '' }} />
                <ProposalList customColors={customColors || undefined} proposals={proposals} />
              </div>
              {/* <Footer /> */}
            </Route>
          </Switch>
          <Bottombar itemsInCart={Object.keys(shoppingCart).length > 0} phoneNumber={generalInfo?.phone} />
        </MasterLayout>
      ) : (
        <div></div>
      )}
    </RestaurantProviderContext.Provider>
  );
};

export default Restaurant;
