import React from 'react';
import MasterLayout from '../../components/MasterLayout/MasterLayout';

import logoUrl from '../../assets/img/logo.png';

const Home = () => {
  return (
    <MasterLayout>
      <div className="absolute-center">
        <img src={logoUrl} alt="risto-logo" width="250" height="138" />
      </div>
    </MasterLayout>
  );
};

export default Home;
