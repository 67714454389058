import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import getAllergens, { AllergensInfo } from '../../services/getAllergens';
import { AllergensModalProps } from './AllergensModal.model';
import './AllergensModal.sass';

const AllergensModal: React.FC<AllergensModalProps> = ({ isOpen, closeCallback }) => {
  const [allergensModal, setAllergensModal] = useState<AllergensInfo | undefined>(undefined);

  const { titleModal, law, description, label, allergens } = allergensModal || {};

  useEffect(() => {
    getAllergens().then((allergensInfo) => setAllergensModal(allergensInfo));
  }, []);

  return (
    <Modal isOpen={isOpen} closeCallback={closeCallback}>
      {allergensModal && (
        <>
          <div className="modal-title">{titleModal}</div>
          <div className="modal-law">{law}</div>
          <div className="modal-description" dangerouslySetInnerHTML={{ __html: description || '' }} />
          <div className="allergens-container">
            <div className="allergens-title">{label}</div>
            {allergens?.map(({ title, description, number }) => (
              <div key={number} className="single-allergen">
                <img src={`/img/allergens/${number}.svg`} alt="" />
                <div className="allergen-text-holder">
                  <div className="allergen-title">{title}</div>
                  <div className="allergen-description" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AllergensModal;
