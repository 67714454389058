import React, { useEffect } from 'react';
import { ProposalListProps } from './ProposalList.model';

import './ProposalList.sass';
import { NavLink, useParams } from 'react-router-dom';

const ProposalList: React.FC<ProposalListProps> = ({ proposals, horizontalLayout, customColors = {} }) => {
  const { restaurantName, proposalName } = useParams();

  useEffect(() => {
    const proposalActive = proposals?.find(({ url }) => url === proposalName);
    const proposalActiveId = proposalActive?.id;
    if (proposalActiveId) {
      const tabContainer = document.querySelector('.proposal-wrapper');
      const tabActive = tabContainer?.querySelector(`.single-proposal.tab-${proposalActiveId}`);
      const main = document.getElementById('root');
      if (tabActive && tabContainer && main) {
        const { x: activeOffset, width: tabWidth } = tabActive.getBoundingClientRect();
        const { x: mainOffset, width: mainWidth } = main.getBoundingClientRect();
        const totalOffset = activeOffset - mainOffset - mainWidth / 2 + tabWidth / 2;
        tabContainer.scrollBy({
          top: 0,
          left: totalOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [proposalName, proposals]);

  const {
    buttonBgColor = '#FAD6D0',
    buttonTextColor = '#444444',
    tabBgColor = '#ffffff',
    tabTextColor = '#444444',
    tabActiveBgColor = '#f05858',
    tabActiveTextColor = '#ffffff',
  } = customColors;

  return (
    <div
      style={{
        backgroundColor: horizontalLayout ? buttonBgColor : '#ffffff',
      }}
      className={`proposal-container ${horizontalLayout ? 'horizontal-layout' : ''}`}
    >
      {!horizontalLayout && <div className="proposal-title">Consulta le nostre proposte</div>}

      <div className="proposal-wrapper">
        <div className="gap"></div>
        {proposals?.map(({ name, url, id, iconSvg }) => (
          <NavLink
            style={{
              backgroundColor: horizontalLayout ? tabBgColor : buttonBgColor,
              color: horizontalLayout ? tabTextColor : buttonTextColor,
            }}
            activeStyle={{
              backgroundColor: tabActiveBgColor,
              color: tabActiveTextColor,
            }}
            to={`/${restaurantName}/${id}`}
            key={id}
            className={`single-proposal tab-${id}`}
          >
            <div dangerouslySetInnerHTML={{ __html: iconSvg }} />
            <span dangerouslySetInnerHTML={{ __html: name }}></span>
            {!horizontalLayout && <div className="arrow-right" />}
          </NavLink>
        ))}
        <div className="gap"></div>
      </div>
    </div>
  );
};

export default ProposalList;
