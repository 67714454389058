import React, { useState } from 'react';
import { HeaderProps } from './Header.model';
import { Link, useParams, useHistory } from 'react-router-dom';

import './Header.sass';
import Modal from '../Modal/Modal';
import { getCurrentLanguage, setCurrentLanguage } from '../../services/currentLanguageManager';

const Header: React.FC<HeaderProps> = ({ logoUrl, languages = [] }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const currentLanguage = getCurrentLanguage();
  const activeLanguage = languages.find(({ code }) => code === currentLanguage);
  const history = useHistory();

  const { restaurantName } = useParams();

  /**
   *
   * @function changeLanguage - change language and re-render application
   * @param {string} code - new language code
   */
  const changeLanguage = (code: string) => {
    //check if user click currentLanguage
    if (currentLanguage === code) return;

    setCurrentLanguage(code);
    history.push(history.location.pathname);
    //wait some times to let user see input clicked
    setTimeout(() => {
      //close modal
      setOpenModal(false);
      //wait modal animations before reload applications
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }, 200);
  };

  return (
    <header className="header">
      <Link className="absolute-center" to={`/${restaurantName}`}>
        <img src={logoUrl} alt="" />
      </Link>
      {languages.length > 1 && (
        <div className="languages-selector">
          <div className="language-active" onClick={() => setOpenModal(true)}>
            <img src={activeLanguage?.imgUrl} alt="" />
            <strong>{activeLanguage?.shortName}</strong>
            <span></span>
          </div>
          <Modal isOpen={openModal} closeCallback={() => setOpenModal(false)}>
            <div className="languages">
              {languages.map(({ imgUrl, longName, code }) => {
                return (
                  <div onClick={() => changeLanguage(code)} className="single-language" key={longName}>
                    {code === activeLanguage?.code ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510">
                        <path
                          d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5    C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z     M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"
                          data-original="#000000"
                          fill="#00AF80"
                        />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510">
                        <path
                          d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459    c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"
                          data-original="#000000"
                          fill="#5F7D95"
                        />
                      </svg>
                    )}
                    <strong>{longName}</strong>
                    <img src={imgUrl} alt="" />
                  </div>
                );
              })}
            </div>
          </Modal>
        </div>
      )}
    </header>
  );
};

export default Header;
