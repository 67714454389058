import { useState, useEffect } from 'react';

/**
 * @customHook useDeviceHeight - custom hook used to detect current device height
 * @param {number} percentage - percentage of device height
 * 
 * @returns {number} height - height of current device
 */
const useDeviceHeight = (percentage: number = 100): number => {
  const [height, setHeight] = useState<number>(0);

  const checkHeight = () => {
    const currentHeight = (percentage * window.innerHeight) / 100;
    if (currentHeight !== height) {
      setHeight(currentHeight)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkHeight(), [])

  useEffect(() => {
    // window.addEventListener("resize", checkHeight);
    return () => {
      // window.removeEventListener("resize", checkHeight)
    }
  })


  return height;
}

export default useDeviceHeight;