import { instance } from '../App';

export type Proposal = {
  name: string;
  id: number;
  iconSvg: string;
  url: string;
};

export type ProposalListModel = Array<Proposal>;

/**
 * @function getProposals - get all proposals of a restaurant by given name
 * @param {string} ristoName name of the restaurant
 *
 * @returns {Promise<ProposalListModel>} proposals list
 */
const getProposals = async (ristoName: string): Promise<ProposalListModel> => {
  let response: Promise<any>;
  response = await instance
    .get('/get_proposal.php', {
      params: {
        slug: ristoName,
      },
    })
    .then((resp) => resp.data);
  return response;
};

export default getProposals;
