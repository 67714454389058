import { instance } from '../App';
import { LanguageSelectorProps } from '../components/Header/Header.model';

export type CustomColors = {
  bgColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  tabBgColor: string;
  tabTextColor: string;
  tabActiveBgColor: string;
  tabActiveTextColor: string;
};

export type GeneralInfoModel = {
  seoTitle: string;
  name: string;
  logoUrl: string;
  headingRowImgUrl: string;
  description: string;
  phone: string;
  customColors: CustomColors;
} & LanguageSelectorProps;

/**
 * @function getGeneralInfo - get general info of a restaurant by given name
 * @param {string} ristoName name of the restaurant
 *
 * @returns {Promise<GeneralInfoModel>} restaurantInfo
 */
const getGeneralInfo = async (ristoName: string): Promise<GeneralInfoModel> => {
  let response: Promise<any>;
  response = await instance
    .get('/get_general_info.php', {
      params: {
        slug: ristoName,
      },
    })
    .then((resp) => resp.data);
  return response;
};

export default getGeneralInfo;
