import React, { useState, useEffect } from 'react';

import './ShoppingBag.sass';
import { Link, useParams } from 'react-router-dom';
import getShoppingBag, { ShoppingDetail } from '../../../services/getShoppingBag';
import QuantitySelector from '../../../components/QuantitySelector/QuantitySelector';

const ShoppingBag = () => {
  const { restaurantName } = useParams();

  const [shoppingBag, setShoppingBag] = useState<ShoppingDetail>({} as ShoppingDetail);

  useEffect(() => {
    getShoppingBag(restaurantName).then((currentCart) => {
      setShoppingBag(currentCart);
    });
  }, [restaurantName]);

  const updateValue = (id: string, value: string | number) => {
    console.log(value);
  };

  const removeItem = (id: string) => {
    console.log(id);
  };

  return (
    <div className="shopping-bag">
      <div className="heading">
        <div className="title">Carrello</div>
        <Link to={`/${restaurantName}`}>Torna indietro</Link>
      </div>
      <div className="cart">
        <div className="items-container">
          {shoppingBag?.items?.map(({ id, name, price, quantity }) => {
            return (
              <div key={id}>
                <div className="column">
                  <div className="title">{name}</div>
                  <QuantitySelector value={quantity} onUpdate={(value) => updateValue(id, value)} />
                </div>
                <div className="column">
                  <div className="remove-item" onClick={() => removeItem(id)}>
                    <img src="/img/icons/trash.svg" alt="" />
                  </div>
                  <div className="price">{price}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="total-price">
          <div className="dt">Totale ordine</div>
          <div className="dd">{shoppingBag?.totalPrice}</div>
        </div>
        <Link to={`/${restaurantName}/checkout`} className="btn btn-confirm-order">
          Conferma e inoltra ordine
        </Link>
      </div>
    </div>
  );
};

export default ShoppingBag;
