import { instance } from '../App';

export type ShoppingBagItem = {
  id: string;
  name: string;
  price: string;
  quantity: number | string;
};

export type ShoppingDetail = {
  items: Array<ShoppingBagItem>;
  totalPrice: string;
};

/**
 * @function getShoppingBag - get proposal detail of a restaurant by given name
 * @param {string} ristoName name of the restaurant
 * @param {string} proposal name of the proposal
 *
 * @returns {Promise<ProposalDetail>} proposal detail
 */
const getShoppingBag = async (ristoName: string): Promise<ShoppingDetail> => {
  let response: Promise<ShoppingDetail>;
  response = await instance.get('https://jsonplaceholder.typicode.com/todos').then((resp) => {
    //only for developing purposes
    resp.data = {
      items: [
        {
          id: '323',
          name: 'Tartare di salmone marinato al sesamo',
          price: '€ 33,50',
          quantity: 1,
        },
        {
          id: '3223',
          name: 'Pizza con tartare di salmone',
          price: '€ 13,50',
          quantity: 2,
        },
        {
          id: '32223',
          name: 'Salmone affumicato con verdure grigliate',
          price: '€ 55,50',
          quantity: 2,
        },
      ],
      totalPrice: '€ 57,00',
    };
    return resp.data;
  });
  return response;
};

export default getShoppingBag;
