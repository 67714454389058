import React from 'react';
import { QuantitySelectorProps } from './QuantitySelector.model';

const QuantitySelector: React.FC<QuantitySelectorProps> = ({ onUpdate, value }) => {
  const updateNumber = (modifier: number) => {
    const newNumber = +value + modifier;
    if (newNumber <= 1) {
      onUpdate(1);
    } else {
      onUpdate(newNumber);
    }
  };

  return (
    <div className="quantity">
      <span onClick={() => updateNumber(-1)}>-</span>
      <span>{value || 1}</span>
      <span onClick={() => updateNumber(1)}>+</span>
    </div>
  );
};

export default QuantitySelector;
